import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import SnowflakeIcon from "@material-ui/icons/AcUnit";
import styles from "./styles";
import emoji from "../Emoji";

const Logo = ({ isDisabled = false, classes }) => {
  const winterHoliday = useMemo(() => {
    const today = new Date();
    const start =
      today.getMonth() === 0
        ? new Date(today.getFullYear() - 1, 11, 24)
        : new Date(today.getFullYear(), 11, 24);
    const end = new Date(start.getTime() + 20 * 24 * 60 * 60 * 1000); // Jan 7
    return today >= start && today <= end;
  }, []);

  return (
    <Link
      to={isDisabled ? "#" : "/"}
      className={classes.logoLinkWrapper}
      aria-label={isDisabled ? "Logo is disabled" : "Navigate to home"}
    >
      <SvgIcon className={classes.logo} viewBox="0 0 44.99 45.01">
        {winterHoliday ? (
          <SnowflakeIcon />
        ) : (
          <g id="Layer_x0020_1">
            <g id="_3082038511072">
              <polygon
                className="fil0"
                points="40.53,26.98 31.51,17.96 24.73,24.74 22.5,26.98 20.26,24.74 13.48,17.96 4.47,26.98 -0,22.51 
                11.25,11.26 13.48,9.03 15.72,11.26 22.5,18.04 29.28,11.26 31.51,9.03 33.75,11.26 44.99,22.51"
              />
              <polygon
                className="fil0"
                points="35.98,31.52 24.73,42.77 22.5,45.01 20.26,42.77 9.01,31.52 13.48,27.06 22.5,36.07 31.51,27.06"
              />
              <polygon
                className="fil0"
                points="26.97,4.48 22.5,8.94 18.04,4.47 22.5,0"
              />
            </g>
          </g>
        )}
      </SvgIcon>
      <Typography variant="h6">
        {winterHoliday
          ? emoji("CREWIS🎅R", { style: { width: "26px", height: "26px" } })
          : "CREWISOR"}
      </Typography>
    </Link>
  );
};

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

export default withStyles(styles)(Logo);
